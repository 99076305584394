/**
 * Created by michielknevels on 22/09/17.
 */

import TranslateUtil from '../util/translation/TranslationUtil';
import { LANGUAGE_SET } from '../actions/types';


const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case LANGUAGE_SET:
        let shouldSet = false;
        if (action.payload.isDefault) {
            if (!state) {
                shouldSet = true;
            }
        } else {
            shouldSet = true;
        }
        if (shouldSet) {
            const { language } = action.payload;
            if (language) {
                TranslateUtil.setLanguage(language);

                return language;
            }
        }
        return state;

    default:
        return state;
    }
};
