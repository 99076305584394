/**
 * Created by michielknevels on 31/10/17.
 */

import {
    ALERT_SHOW,
    ALERT_HIDE,
    ALERT_LOCK,
    ALERT_UNLOCK
} from '../actions/types';

const INITIAL_STATE = {
    show: false,
    locked: false,
    title: null,
    message: null,
    buttons: null,
    level: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case ALERT_SHOW:
        return {
            ...state,
            show: true,
            title: action.payload.title,
            message: action.payload.message,
            buttons: action.payload.buttons,
            level: action.payload.level,
        };
    case ALERT_LOCK:
        if (state.show) {
            return { ...state, show: false, locked: true };
        }
        return state;
    case ALERT_UNLOCK:
        if (state.locked) {
            return { ...state, show: true, locked: false };
        }
        return state;
    case ALERT_HIDE:
        return { ...INITIAL_STATE };
    default:
        return state;
    }
};
