import React, { PureComponent } from 'react';
import { Button } from '@blueprintjs/core';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import TranslateUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import MobIcon from '../../components/icon/MobIcon';

export default class NavBarLink extends PureComponent {
    render() {
        const {
            url, translation, icon, hasSolidIcon, active, style
        } = this.props;
        return (
            <Button
                style={style}
                active={active}
                onClick={() => this.props.go(url)}
                className="bp3-minimal"
                icon={<MobIcon name={hasSolidIcon && active ? `${icon}-solid` : icon} />}
                text={TextUtil.capitalize(TranslateUtil.translateKey(translation))}
            />
        );
    }
}
