import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import firebase from 'firebase';
import AuthenticationScreen from './components/auth/screen/AuthenticationScreen';
import PageNotFound from './shared-react/components/general/PageNotFound';
import { appReducer } from './reducers';
import CustomersScreen from './components/customer/screen/CustomersScreen';
import Navbar from './components/general/nav/Navbar';
import ConfigScreen from './components/customer/screen/ConfigScreen';
import Footer from './components/general/footer/Footer';

// eslint-disable-next-line import/no-mutable-exports
export let store = null;

class App extends PureComponent {
    render() {
        store = createStore(appReducer, {}, applyMiddleware(ReduxThunk));
        const config = {
            apiKey: 'AIzaSyCNLM1vtrBUHypIdwRh7WfHaN-9rYXPaT8',
            authDomain: 'moonchase-manager.firebaseapp.com',
            databaseURL: 'https://moonchase-manager.firebaseio.com',
            projectId: 'moonchase-manager',
            storageBucket: 'moonchase-manager.appspot.com',
            messagingSenderId: '636786906853'
        };
        firebase.initializeApp(config);
        firebase.app().functions('us-central1');
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <div style={styles.containerStyle}>
                        <div style={styles.contentStyle}>
                            <Route component={Navbar} />
                            <div style={styles.contentContainerStyle}>
                                <Switch>
                                    <Route path="/" exact component={AuthenticationScreen} />
                                    <Route path="/customers" exact component={CustomersScreen} />
                                    <Route path="/customer/:customer" exact component={ConfigScreen} />
                                    <Route component={PageNotFound} />
                                </Switch>
                            </div>
                        </div>
                        <div style={styles.footerStyle}>
                            <Route path="/" component={Footer} />
                        </div>
                    </div>
                </BrowserRouter>
            </Provider>

        );
    }
}

const styles = {
    containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    contentStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    footerStyle: {
        flexShrink: 0
    },
    contentContainerStyle: {
        paddingTop: 50,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    }
};

export default App;
