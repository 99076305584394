import React, { Component } from 'react';
import { Button, Card, Navbar } from '@blueprintjs/core';
import { Elevation } from '@blueprintjs/core/lib/cjs/common/elevation';
import { Alignment } from '@blueprintjs/core/lib/cjs/common/alignment';
import background from '../../../assets/background.png';
import ColorUtil from '../../../shared-javascript/util/color/ColorUtil';
import Auth from '../tab/Auth';
import MobIcon from '../../../shared-react/components/icon/MobIcon';
import TranslateKey from '../../../shared-react/components/translate/TranslateKey';
import RegisterUser from '../tab/RegisterUser';

export default class AuthenticationScreen extends Component {
    constructor() {
        super();
        this.state = {
        };
        this.setTab = this.setTab.bind(this);
    }

    setTab(tab) {
        this.setState((previousState) => ({ ...previousState, tab }));
    }


    renderTab() {
        switch (this.state.tab) {
        case 'login':
        default:
            return <Auth history={this.props.history} />;
        case 'register':
            return <RegisterUser history={this.props.history} />;
        }
    }


    render() {
        return (
            <div style={styles.containerStyle}>
                <Card style={styles.cardStyle} elevation={Elevation.TWO}>
                    <Navbar style={{ marginBottom: 10 }}>
                        <Navbar.Group align={Alignment.CENTER}>
                            <Button onClick={() => this.setTab('login')} active={this.state.tab === 'login'} className="bp3-minimal" icon={<MobIcon name="security" />} text={<TranslateKey capitalize name="login" />} />
                            <Button onClick={() => this.setTab('register')} active={this.state.tab === 'register'} className="bp3-minimal" icon={<MobIcon name="patient-add" />} text={<TranslateKey capitalize name="register" />} />
                        </Navbar.Group>
                    </Navbar>
                    {this.renderTab()}
                </Card>
            </div>
        );
    }
}

const styles = {
    containerStyle: {
        background: `url(${background}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    cardStyle: {
        width: 500
    },
    logoContainerStyle: {
        marginTop: 10,
        marginBottom: 10,
    },
    logoStyle: {
        width: '100%'
    },
    errorStyle: {
        color: ColorUtil.getErrorColor(),
        textAlign: 'center'
    }
};
