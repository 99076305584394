/**
 * Created by michielknevels on 28/09/17.
 */

export default class ColorUtil {
    static getColorFromCode(code) {
        switch (code) {
        case 'green': return ColorUtil.getGreen();
        case 'orange': return ColorUtil.getWarningColor();
        case 'red': return ColorUtil.getErrorColor();
        case 'pink': return ColorUtil.getPink();
        case 'light-blue': return ColorUtil.getLightBlue();
        case 'blue': return ColorUtil.getInfoBlue();
        case 'grey': return ColorUtil.getNeutralColor();
        case 'dark-grey': return ColorUtil.getDarkGrey();
        default: return ColorUtil.getTransparent();
        }
    }

    // main colors
    static getSuccessColor(opacity) { return `rgba(19, 145, 141, ${opacity || 255})`; }
    static getLightWarningColor() { return '#E8D91A'; }
    static getWarningColor() { return '#FFA818'; }
    static getErrorColor() { return '#D0021B'; }
    static getLighterNeutralColor() { return '#DDD'; }
    static getNeutralColor() { return '#AAAAAA'; }
    static getGreenColor() { return 'rgba(113, 192, 26, 1)'; }

    // Black-grey, whites
    static getBackgroundColor() {
        return '#FFF';
    }

    static getPrimaryColor() {
        return '#000';
    }

    static getInversePrimaryColor() {
        return '#FFF';
    }

    static getSecondaryColor() {
        return '#9B9B9B';
    }

    static getButtonColor() {
        return '#E0E0E0';
    }

    static getDarkWhite() {
        return '#f5f5f5';
    }

    static getLightGrey() {
        return '#e5e5e5';
    }

    static getDarkGrey() {
        return '#777';
    }

    static getBorderColor() {
        return 'rgba(0, 0, 0, 0.12)';
    }

    static getNotificationBackgroundColor() {
        return 'rgba(240,240,240,0.99)';
    }

    static getTransparent() {
        return 'transparent';
    }

    static getBlack() {
        return '#000';
    }

    static getWhite() {
        return '#fff';
    }


    // special colors

    static getPink() {
        return '#9E2484';
    }

    static getDarkGreen() {
        return '#024442';
    }

    static getKhaki() {
        return '#354A21';
    }

    static getLightOrange() {
        return '#FFDF9E';
    }

    static getLightBlue() {
        return '#83BDF7';
    }

    // todo rename to blue
    static getInfoBlue() {
        return '#4a90e2';
    }

    static getDarkBlue() {
        return '#0666C4';
    }

    static getGreen() {
        return '#0D9E47';
    }
}
