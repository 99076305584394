import React, { Component } from 'react';
import firebase from 'firebase';
import ReactTable from 'react-table';
import {
    Button, Dialog, FormGroup, InputGroup
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import MobIcon from '../../../shared-react/components/icon/MobIcon';
import TranslateUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import TranslateKey from '../../../shared-react/components/translate/TranslateKey';


export default class CustomersScreen extends Component {
    constructor() {
        super();
        this.state = {
            customerName: null,
            editorOpen: false,
            customers: []
        };
        this.onAdd = this.onAdd.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
        this.onCustomerNameChange = this.onCustomerNameChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        firebase.firestore().collection('customers')
            .onSnapshot((querySnapshot) => {
                const customers = [];
                querySnapshot.forEach((doc) => {
                    const { id } = doc;
                    customers.push({ ...doc.data(), id });
                });
                this.setState((previousState) => ({ ...previousState, customers }));
            });
    }

    onAdd() {
        this.setState((previousState) => ({ ...previousState, editorOpen: true, customerName: null }));
    }

    closeEditor() {
        this.setState((previousState) => ({ ...previousState, editorOpen: false, customerName: null }));
    }

    onCustomerNameChange(ev) {
        const customerName = ev.target.value;
        this.setState((previousState) => ({ ...previousState, customerName }));
    }

    onSubmit(event) {
        event.preventDefault();
        const { customerName } = this.state;
        this.closeEditor();
        firebase.firestore().collection('customers').add({
            name: customerName,
            creator: firebase.auth().currentUser.email
        })
            .then((docRef) => {
                console.log('Document written with ID: ', docRef.id);
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    render() {
        const columns = [{
            Header: <TranslateKey name="customer" />,
            Cell: (props) => <Link to={`customer/${props.original.id}`}>{props.value}</Link>,
            accessor: 'name'
        },
        {
            Header: <TranslateKey name="user" />,
            accessor: 'creator'
        }
        ];

        return (
            <div style={styles.containerStyle}>
                <ReactTable
                    noDataText={TextUtil.capitalize(TranslateUtil.translateKey('no_data'))}
                    style={styles.tableStyle}
                    minRows={3}
                    showPagination={false}
                    data={this.state.customers}
                    columns={columns}
                />
                <Button
                    style={styles.buttonStyle}
                    onClick={this.onAdd}
                    icon={<MobIcon name="add" />}
                />
                <Dialog
                    title={TranslateUtil.translateKey('create_customer')}
                    canOutsideClickClose
                    onClose={this.closeEditor}
                    isOpen={this.state.editorOpen}
                >
                    <form
                        style={styles.formStyle}
                        onSubmit={this.onSubmit}
                    >
                        <FormGroup
                            label={TextUtil.capitalize(TranslateUtil.translateKey('customer'))}
                            labelFor="customer"
                        >
                            <InputGroup
                                onChange={this.onCustomerNameChange}
                                autoFocus
                                id="customer"
                            />
                        </FormGroup>
                        <InputGroup
                            value={TextUtil.capitalize(TranslateUtil.translateKey('send'))}
                            type="submit"
                            id="send"
                        />
                    </form>
                </Dialog>
            </div>
        );
    }
}

const styles = {
    containerStyle: {
        padding: 10
    },
    formStyle: {
        padding: 5
    },
    tableStyle: {
        margin: 20
    },
    buttonStyle: {
        marginLeft: 20
    }
};
