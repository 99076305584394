/* eslint-disable prefer-rest-params */
/**
 * Created by michielknevels on 11/09/17.
 */

import Translations from './Translations';

export default class TranslateUtil {
    static language = null;

    static setLanguage(language) {
        TranslateUtil.language = language.toUpperCase();
    }

    static getParentLanguage(language) {
        return Translations['%parent%'][language];
    }

    static getLanguage() {
        if (TranslateUtil.language) {
            return TranslateUtil.language;
        }
        return 'NL';
    }

    static translateObject(translations) {
        if (translations) {
            const language = TranslateUtil.getLanguage();
            if (translations.hasOwnProperty(language)) {
                let translated = translations[language];
                if (!translated) {
                    translated = translations[TranslateUtil.getParentLanguage(language)];
                }
                return translated;
            }
        }
    }

    static translateKey(translationKey) {
        let translation = TranslateUtil.translateObject(Translations[translationKey]);
        Object.keys(arguments).forEach((key) => {
            const argument = arguments[key];
            if (key !== '0') {
                translation = translation.replace(`{${parseInt(key, 10) - 1}}`, argument);
            }
        });
        return translation;
    }

    static convertBrowserLanguage(lang) {
        if (Translations.app_name.hasOwnProperty(lang.toUpperCase())) {
            return lang.toUpperCase();
        }
        return 'NL';
    }
}
