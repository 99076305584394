import React, { PureComponent } from 'react';
import {
    Button, Navbar, Alignment, Popover, Alert, ProgressBar
} from '@blueprintjs/core';
import _ from 'lodash';
import { Intent } from '@blueprintjs/core/lib/cjs/common/intent';
import firebase from 'firebase';
import { saveAs } from 'file-saver';
import MobIcon from '../../../shared-react/components/icon/MobIcon';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import TranslationUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import TranslateKey from '../../../shared-react/components/translate/TranslateKey';
import Loading from '../../../shared-react/components/loading/Loading';

const pkg = require('../../../../package.json');

export default class Footer extends PureComponent {
    constructor() {
        super();
        this.state = {
            loggingOut: false,
            user: null,
            apps: [],
            downloading: false
        };
        this.startLogout = this.startLogout.bind(this);
        this.stopLogout = this.stopLogout.bind(this);
        this.logout = this.logout.bind(this);
        this.downloadApp = this.downloadApp.bind(this);
        firebase.auth().onAuthStateChanged((user) => {
            this.fetchApps();
            this.setState((previousState) => ({ ...previousState, user }));
        });
    }

    fetchApps() {
        firebase.firestore().collection('apps')
            .onSnapshot((querySnapshot) => {
                const apps = [];
                querySnapshot.forEach((doc) => {
                    const { id } = doc;
                    apps.push({ ...doc.data(), id });
                });
                this.setState((previousState) => ({ ...previousState, apps }));
            });
    }


    startLogout() {
        this.setState((previousState) => ({ ...previousState, loggingOut: true }));
    }

    stopLogout() {
        this.setState((previousState) => ({ ...previousState, loggingOut: false }));
    }

    logout() {
        firebase.auth().signOut();
        window.location = '/';
    }

    downloadApp(path) {
        this.setState((previousState) => ({ ...previousState, downloading: true }));
        const parts = path.split('/');
        const fileName = parts[parts.length - 1];
        const storage = firebase.storage();
        const self = this;
        storage.refFromURL(path).getDownloadURL().then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function () {
                self.setState((previousState) => ({ ...previousState, downloading: false }));
                const blob = xhr.response;
                saveAs(blob, fileName);
            };
            xhr.open('GET', url);
            xhr.send();
        });
    }

    render() {
        return (
            <div>
                {
                    _.map(this.props.progress, (val, key) => (
                        <ProgressBar
                            key={key}
                            intent={Intent.SUCCESS}
                            value={val}
                        />
                    ))
                }
                <Navbar style={styles.containerStyle}>
                    <Navbar.Group style={styles.containerStyle} align={Alignment.RIGHT}>
                        <Popover>
                            <Button
                                disabled={!this.state.user}
                                className="bp3-minimal"
                                icon={this.state.downloading ? <Loading size={15} /> : <MobIcon name="device" />}
                            />
                            <div style={styles.appContainerStyle}>
                                {
                                    _.map(this.state.apps, (val, key) => (
                                        <Button
                                            onClick={() => this.downloadApp(val.location)}
                                            className="bp3-minimal"
                                            key={`app-${key}`}
                                            value={val.id}
                                        >
                                            <p>{`${val.version}: ${val.comment}`}</p>
                                        </Button>

                                    ))
                                }
                            </div>
                        </Popover>
                        <Navbar.Divider />
                        <div style={styles.versionStyle} className="bp3-navbar-heading">{pkg.version}</div>
                        <Navbar.Divider />
                        <Button
                            disabled={!this.state.user}
                            onClick={this.startLogout}
                            className="bp3-minimal"
                            icon={<MobIcon name="standby" />}
                        />
                        <Alert
                            canOutsideClickCancel
                            cancelButtonText={TextUtil.capitalize(TranslationUtil.translateKey('no'))}
                            confirmButtonText={TextUtil.capitalize(TranslationUtil.translateKey('yes'))}
                            intent={Intent.DANGER}
                            isOpen={this.state.loggingOut}
                            onCancel={this.stopLogout}
                            onConfirm={this.logout}
                        >
                            <h1><TranslateKey capitalize name="logout" /></h1>
                            <p>
                                <TranslateKey capitalize name="are_you_sure_logout" />
                            </p>
                        </Alert>
                    </Navbar.Group>
                </Navbar>
            </div>

        );
    }
}

const styles = {
    containerStyle: {
        height: 30
    },
    versionStyle: {
        fontSize: 12,
        marginRight: 0
    },
    qrcodeStyle: {
        margin: 10
    },
    appContainerStyle: {
        display: 'flex',
        flexDirection: 'column'
    }
};
