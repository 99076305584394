import React, { PureComponent } from 'react';
import { RingLoader } from 'react-spinners';

export default class Loading extends PureComponent {
    render() {
        return (
            <div style={styles.mainContainerStyle}>
                <RingLoader
                    size={this.props.size}
                    color="#13918D"
                />
            </div>
        );
    }
}

const styles = {
    mainContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
    },
};
