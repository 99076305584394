import React, { PureComponent } from 'react';
import NoData from './NoData';

export default class PageNotFound extends PureComponent {
    render() {
        return (
            <div style={styles.mainContainerStyle}>
                <NoData translation="page_not_found" />
            </div>
        );
    }
}

const styles = {
    mainContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
    },
};
