/**
 * Created by michielknevels on 11/10/17.
 */

export default class UrlUtils {
    static location = null;
    static hashListeners = {};

    static getParameter = (key) => {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === key) { return pair[1]; }
        }
        return (false);
    };

    static getParameters = () => {
        const result = {};
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            // eslint-disable-next-line prefer-destructuring
            result[pair[0]] = pair[1];
        }
        return result;
    };

    static isActive(currentUrl, activeUrls) {
        let result = false;
        activeUrls.forEach((url) => {
            if (url === currentUrl || currentUrl.match(url)) {
                result = true;
            }
        });
        return result;
    }

    static setUrl(location) {
        this.location = location;
        if (this.hashListeners.hasOwnProperty(location.hash)) {
            this.hashListeners[location.hash]();
        }
    }

    static registerHashListener(hash, callback) {
        this.hashListeners[`#${hash}`] = callback;
        if (this.location.hash === `#${hash}`) {
            callback();
        }
    }

    static unregisterHashListener(hash) {
        this.hashListeners[`#${hash}`] = null;
        delete this.hashListeners[`#${hash}`];
    }
}
