import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import Loading from '../../../shared-react/components/loading/Loading';

export default class ConfigBarcode extends Component {
    constructor(props) {
        super();
        this.state = {
            qrCode: `${JSON.stringify(props.fullConfig)}oijwef98h2984u98fge98349f893r9hf9h39h239uh`
        };
    }

    // componentDidMount() {
    //     return;
    //     const { config } = this.props;
    //     axios.get(`https://us-central1-moonchase-manager.cloudfunctions.net/getQrData?config=${config}`)
    //         .then((response) => {
    //             const { data, signature } = response.data;
    //             const qrCode = `${data}${signature}`;
    //             this.setState((previousState) => ({ ...previousState, qrCode }));
    //         });
    //     const getQrData = firebase.functions().httpsCallable('getQrData');
    //     getQrData({ customer, config }).then((result) => {
    //         console.error(result);import { Base64 } from 'js-base64';
    //     });
    // }

    render() {
        const size = 400;
        return (
            <div style={styles.qrcodeStyle}>
                {
                    this.state.qrCode
                        ? (
                            <QRCode
                                size={size}
                                value={this.state.qrCode}
                            />
                        ) : <Loading size={size} />
                }

            </div>
        );
    }
}

const styles = {
    qrcodeStyle: {
        margin: 10
    }
};
