/**
 * Created by michielknevels on 7/11/17.
 */

export default class TextUtil {
    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static fullCapitalize(string) {
        return string.toUpperCase();
    }
}
