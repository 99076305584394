import React, { PureComponent } from 'react';
import { Navbar, Alignment } from '@blueprintjs/core';
import firebase from 'firebase';
import NavBarLink from '../../../shared-react/util/nav/NavBarLink';
import UrlUtils from '../../../shared-react/util/url/UrlUtils';

export default class NavbarComponent extends PureComponent {
    constructor() {
        super();
        this.state = {
            user: null
        };
        this.go = this.go.bind(this);
        firebase.auth().onAuthStateChanged((user) => {
            this.setState((previousState) => ({ ...previousState, user }));
        });
    }

    go(url) {
        this.props.history.push(url);
    }

    renderLink(url, translation, icon, hasSolidIcon, activeUrls) {
        const { pathname } = this.props.location;
        const active = UrlUtils.isActive(pathname, [url].concat(activeUrls));

        return (
            <NavBarLink
                go={this.go}
                url={url}
                translation={translation}
                icon={icon}
                hasSolidIcon={hasSolidIcon}
                active={active}
            />
        );
    }

    render() {
        if (!this.state.user) {
            return null;
        }
        return (
            <Navbar fixedToTop>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Moonchase</Navbar.Heading>
                    <Navbar.Divider />
                    {this.renderLink('/customers', 'customers', 'hospital', true, [])}
                </Navbar.Group>
            </Navbar>
        );
    }
}
