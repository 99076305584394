import React, { Component } from 'react';
import validUrl from 'valid-url';
import ReactTable from 'react-table';
import firebase from 'firebase';
import {
    Button, Checkbox, Dialog, FormGroup, InputGroup, Popover
} from '@blueprintjs/core';
import { Position } from '@blueprintjs/core/lib/cjs/common/position';
import MobIcon from '../../../shared-react/components/icon/MobIcon';
import TranslateUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import ConfigBarcode from '../item/ConfigBarcode';
import TranslateKey from '../../../shared-react/components/translate/TranslateKey';

export default class ConfigScreen extends Component {
    constructor() {
        super();
        this.state = {
            editorOpen: false,
            customer: null,
            name: null,
            url: null,
            scanRequired: false,
            scanPrescription: false,
            registerId: false,
            sbvzControl: false,
            acceptanceEnv: false,
            selectedConfig: null,
            showSampleIcon: false,
            enterCollectTime: false
        };
        this.onAdd = this.onAdd.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onUrlChange = this.onUrlChange.bind(this);
        this.onScanRequiredChange = this.onScanRequiredChange.bind(this);
        this.onScanPrescriptionChanged = this.onScanPrescriptionChanged.bind(this);
        this.onAcceptanceEnvChanged = this.onAcceptanceEnvChanged.bind(this);
        this.onRegisterIdChanged = this.onRegisterIdChanged.bind(this);
        this.onSbvzControlChanged = this.onSbvzControlChanged.bind(this);
        this.checkInput = this.checkInput.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onShowSampleIconChanged = this.onShowSampleIconChanged.bind(this);
        this.onEnterCollectTimeChanged = this.onEnterCollectTimeChanged.bind(this);
    }

    checkInput() {
        const { name, url } = this.state;
        if (!name || name.length === 0) {
            return false;
        }
        if (!validUrl.isUri(url)) {
            return false;
        }
        return true;
    }

    onEdit(id) {
        let config = null;
        this.state.configs.forEach((c) => {
            if (c.id === id) {
                config = c;
            }
        });
        const {
            name, url, scanRequired, scanPrescription, registerId, sbvzControl, acceptanceEnv, showSampleIcon, enterCollectTime
        } = config;
        this.setState((previousState) => ({
            ...previousState, name, url, scanRequired, scanPrescription, registerId, sbvzControl, editorOpen: true, selectedConfig: id, acceptanceEnv, showSampleIcon, enterCollectTime
        }));
    }

    onAdd() {
        this.setState((previousState) => ({
            ...previousState, editorOpen: true, name: null, url: null, scanRequired: false, scanPrescription: false, selectedConfig: null, acceptanceEnv: false
        }));
    }

    closeEditor() {
        this.setState((previousState) => ({ ...previousState, editorOpen: false, name: null }));
    }

    onNameChange(ev) {
        const name = ev.target.value;
        this.setState((previousState) => ({ ...previousState, name }));
    }

    onUrlChange(ev) {
        const url = ev.target.value;
        this.setState((previousState) => ({ ...previousState, url }));
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.checkInput()) {
            const {
                name, url, scanRequired, scanPrescription, registerId, sbvzControl, selectedConfig, customerId, acceptanceEnv, showSampleIcon, enterCollectTime
            } = this.state;
            const data = {
                name, url, scanRequired, scanPrescription, registerId, sbvzControl, acceptanceEnv, showSampleIcon, enterCollectTime, updator: firebase.auth().currentUser.email, customer: customerId
            };

            if (selectedConfig) {
                firebase.firestore().collection('configs').doc(selectedConfig).set(data);
            } else {
                firebase.firestore().collection('configs').add(data);
            }
            this.closeEditor();
        } else {
            alert();
        }
    }

    onScanRequiredChange(ev) {
        const scanRequired = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, scanRequired }));
    }

    onScanPrescriptionChanged(ev) {
        const scanPrescription = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, scanPrescription }));
    }

    onAcceptanceEnvChanged(ev) {
        const acceptanceEnv = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, acceptanceEnv }));
    }

    onShowSampleIconChanged(ev) {
        const showSampleIcon = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, showSampleIcon }));
    }

    onEnterCollectTimeChanged(ev) {
        const enterCollectTime = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, enterCollectTime }));
    }

    onRegisterIdChanged(ev) {
        const registerId = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, registerId }));
    }

    onSbvzControlChanged(ev) {
        const sbvzControl = ev.target.checked;
        this.setState((previousState) => ({ ...previousState, sbvzControl }));
    }

    componentDidMount() {
        const customerId = this.props.match.params.customer;
        firebase.firestore().collection('configs').where('customer', '==', customerId)
            .onSnapshot((querySnapshot) => {
                const configs = [];
                querySnapshot.forEach((doc) => {
                    const { id } = doc;
                    configs.push({ ...doc.data(), id });
                });
                this.setState((previousState) => ({ ...previousState, customerId, configs }));
            });
    }


    renderQRCode(config) {
        return (
            <Popover position={Position.LEFT_BOTTOM}>
                <Button className="bp3-minimal" icon={<MobIcon name="scan-barcode" />} />
                <ConfigBarcode fullConfig={config} config={config.id} />
            </Popover>
        );
    }

    render() {
        const columns = [{
            Header: <TranslateKey capitalize name="name" />,
            Cell: (props) => <a onClick={() => { this.onEdit(props.original.id); }}>{props.value}</a>,
            accessor: 'name'
        },
        {
            Header: <TranslateKey name="url" />,
            accessor: 'url'
        },
        {
            Header: <TranslateKey name="scan_required" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'scanRequired'
        },
        {
            Header: <TranslateKey name="scan_prescription" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'scanPrescription'
        },
        {
            Header: <TranslateKey name="can_register_ids" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'registerId'
        },
        {
            Header: <TranslateKey name="can_control_sbvz" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'sbvzControl'
        },
        {
            Header: <TranslateKey name="acceptance_environment" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'acceptanceEnv'
        },
        {
            Header: <TranslateKey name="show_sample_icon" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'showSampleIcon'
        }, {
            Header: <TranslateKey name="enter_collect_time" />,
            Cell: (props) => <TranslateKey capitalize name={props.value ? 'yes' : 'no'} />,
            accessor: 'enterCollectTime'
        },

        {
            Header: <TranslateKey capitalize name="user" />,
            accessor: 'updator'
        },
        {
            Header: <TranslateKey name="qr_code" />,
            Cell: (props) => (this.renderQRCode(props.original)),
            accessor: 'test'
        }
        ];

        return (
            <div style={styles.containerStyle}>
                <ReactTable
                    noDataText={TextUtil.capitalize(TranslateUtil.translateKey('no_data'))}
                    style={styles.tableStyle}
                    minRows={3}
                    showPagination={false}
                    data={this.state.configs}
                    columns={columns}
                />
                <Button
                    style={styles.buttonStyle}
                    onClick={this.onAdd}
                    icon={<MobIcon name="add" />}
                />
                <Dialog
                    title={TranslateUtil.translateKey(this.state.selectedConfig ? 'update_config' : 'create_config')}
                    canOutsideClickClose
                    onClose={this.closeEditor}
                    isOpen={this.state.editorOpen}
                >
                    <form
                        style={styles.formStyle}
                        onSubmit={this.onSubmit}
                    >
                        <FormGroup
                            label={TextUtil.capitalize(TranslateUtil.translateKey('name'))}
                            labelFor="name"
                        >
                            <InputGroup
                                value={this.state.name}
                                onChange={this.onNameChange}
                                autoFocus
                                id="name"
                            />
                        </FormGroup>
                        <FormGroup
                            label={TextUtil.capitalize(TranslateUtil.translateKey('url'))}
                            labelFor="url"
                        >
                            <InputGroup
                                disabled={this.state.selectedConfig}
                                value={this.state.url}
                                onChange={this.onUrlChange}
                                id="url"
                            />
                        </FormGroup>
                        <Checkbox checked={this.state.scanRequired} label={TextUtil.capitalize(TranslateUtil.translateKey('scan_required'))} onChange={this.onScanRequiredChange} />
                        <Checkbox checked={this.state.scanPrescription} label={TextUtil.capitalize(TranslateUtil.translateKey('scan_prescription'))} onChange={this.onScanPrescriptionChanged} />
                        <Checkbox checked={this.state.registerId} label={TextUtil.capitalize(TranslateUtil.translateKey('can_register_ids'))} onChange={this.onRegisterIdChanged} />
                        <Checkbox checked={this.state.sbvzControl} label={TextUtil.capitalize(TranslateUtil.translateKey('can_control_sbvz'))} onChange={this.onSbvzControlChanged} />
                        <Checkbox checked={this.state.acceptanceEnv} label={TextUtil.capitalize(TranslateUtil.translateKey('acceptance_environment'))} onChange={this.onAcceptanceEnvChanged} />
                        <Checkbox checked={this.state.showSampleIcon} label={TextUtil.capitalize(TranslateUtil.translateKey('show_sample_icon'))} onChange={this.onShowSampleIconChanged} />
                        <Checkbox checked={this.state.enterCollectTime} label={TextUtil.capitalize(TranslateUtil.translateKey('enter_collect_time'))} onChange={this.onEnterCollectTimeChanged} />

                        <InputGroup
                            disabled={!this.checkInput()}
                            value={TextUtil.capitalize(TranslateUtil.translateKey('send'))}
                            type="submit"
                            id="send"
                        />
                    </form>
                </Dialog>
            </div>
        );
    }
}

const styles = {
    containerStyle: {
        padding: 10
    },
    formStyle: {
        padding: 5
    },
    tableStyle: {
        margin: 20
    },
    buttonStyle: {
        marginLeft: 20
    }
};
