import React, { Component } from 'react';
import firebase from 'firebase';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import TranslateUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import ColorUtil from '../../../shared-javascript/util/color/ColorUtil';

export default class RegisterUser extends Component {
    constructor() {
        super();
        this.state = {
            username: null,
            password: null,
            passwordCheck: null,
            error: null
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordCheckChange = this.onPasswordCheckChange.bind(this);
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.sendEmailVerification().then(() => {
                    alert(TranslateUtil.translateKey('check_mail_for_verification'));
                    firebase.auth().signOut();
                    window.location = '/';
                });
            } else {
                // No user is signed in.
            }
        });
    }

    onUsernameChange(ev) {
        const username = ev.target.value;
        this.setState((previousState) => ({ ...previousState, username }));
    }

    onPasswordChange(ev) {
        const password = ev.target.value;
        this.setState((previousState) => ({ ...previousState, password }));
    }

    onPasswordCheckChange(ev) {
        const passwordCheck = ev.target.value;
        this.setState((previousState) => ({ ...previousState, passwordCheck }));
    }

    onSubmit(event) {
        event.preventDefault();
        const { username, password, passwordCheck } = this.state;
        if (password === passwordCheck) {
            firebase.auth().createUserWithEmailAndPassword(username, password).catch((error) => {
                this.setState((previousState) => ({ ...previousState, error: error.message }));
            });
        } else {
            this.setState((previousState) => ({ ...previousState, error: TranslateUtil.translateKey('password_check_failed') }));
        }
    }

    render() {
        return (
            <form
                onSubmit={this.onSubmit}
            >
                <FormGroup
                    label={TextUtil.capitalize(TranslateUtil.translateKey('username'))}
                    labelFor="username"
                >
                    <InputGroup
                        onChange={this.onUsernameChange}
                        autoFocus
                        id="username"
                    />
                </FormGroup>
                <FormGroup
                    label={TextUtil.capitalize(TranslateUtil.translateKey('password'))}
                    labelFor="password"
                >
                    <InputGroup
                        onChange={this.onPasswordChange}
                        type="password"
                        id="password"
                    />
                </FormGroup>
                <FormGroup
                    label={TextUtil.capitalize(TranslateUtil.translateKey('password_check'))}
                    labelFor="passwordCheck"
                >
                    <InputGroup
                        onChange={this.onPasswordCheckChange}
                        type="password"
                        id="passwordCheck"
                    />
                </FormGroup>
                <p style={{ color: ColorUtil.getErrorColor() }}>{this.state.error}</p>
                <InputGroup
                    value={TextUtil.capitalize(TranslateUtil.translateKey('register'))}
                    type="submit"
                    id="submit"
                />
            </form>
        );
    }
}
