/**
 * Created by michielknevels on 3/10/17.
 */

import React from 'react';
import MobIcon from '../icon/MobIcon';
import TranslateKey from '../translate/TranslateKey';

function renderText(props) {
    if (props.text) {
        return <p>{props.text}</p>;
    }
    let translationKey = 'no_data';
    if (props.translation) {
        translationKey = props.translation;
    }
    return (
        <TranslateKey
            name={translationKey}
            capitalize
        />
    );
}

function NoData(props) {
    console.log('Component render: NoData');
    const { mainContainerStyle, iconStyle } = styles;
    let iconKey = 'attention';

    if (props.icon) {
        iconKey = props.icon;
    }

    return (
        <div style={mainContainerStyle}>
            <MobIcon
                style={iconStyle}
                name={iconKey}
            />
            {renderText(props)}
        </div>
    );
}

const styles = {
    mainContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
    },
    iconStyle: {
        fontSize: 120,
        color: 'rgba(170, 170, 170, 1)',
        marginBottom: 10
    },
    textStyle: {
        fontSize: 14,
        color: 'rgba(170, 170, 170, 1)',
        marginLeft: 50,
        marginRight: 50,
        textAlign: 'center'
    }
};

export default NoData;
