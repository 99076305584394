import React, { Component } from 'react';
import firebase from 'firebase';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import TextUtil from '../../../shared-javascript/util/text/TextUtil';
import TranslateUtil from '../../../shared-javascript/util/translation/TranslationUtil';
import TranslateKey from '../../../shared-react/components/translate/TranslateKey';
import ColorUtil from '../../../shared-javascript/util/color/ColorUtil';

export default class Auth extends Component {
    constructor() {
        super();
        this.state = {
            username: null,
            password: null,
            error: null
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount() {
        const { history } = this.props;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                history.push('/customers');
            } else {
                // No user is signed in.
            }
        });
    }

    onUsernameChange(ev) {
        const username = ev.target.value;
        this.setState((previousState) => ({ ...previousState, username }));
    }

    onPasswordChange(ev) {
        const password = ev.target.value;
        this.setState((previousState) => ({ ...previousState, password }));
    }

    onSubmit(event) {
        event.preventDefault();
        const { username, password } = this.state;
        firebase.auth().signInWithEmailAndPassword(username, password).catch((error) => {
            this.setState((previousState) => ({ ...previousState, error: error.message }));
        });
    }

    resetPassword() {
        firebase.auth().sendPasswordResetEmail(this.state.username)
            .then(() => {
                alert(TranslateUtil.translateKey('reset_password_success'));
            })
            .catch((error) => {
                this.setState((previousState) => ({ ...previousState, error: error.message }));
            });
    }

    render() {
        return (
            <form
                onSubmit={this.onSubmit}
            >
                <FormGroup
                    label={TextUtil.capitalize(TranslateUtil.translateKey('username'))}
                    labelFor="username"
                >
                    <InputGroup
                        onChange={this.onUsernameChange}
                        autoFocus
                        id="username"
                    />
                </FormGroup>
                <FormGroup
                    label={TextUtil.capitalize(TranslateUtil.translateKey('password'))}
                    labelFor="password"
                >
                    <InputGroup
                        onChange={this.onPasswordChange}
                        type="password"
                        id="password"
                    />
                </FormGroup>
                <p style={{ color: ColorUtil.getErrorColor() }}>{this.state.error}</p>
                <InputGroup
                    value={TextUtil.capitalize(TranslateUtil.translateKey('login'))}
                    type="submit"
                    id="submit"
                />
                <a onClick={this.resetPassword}><TranslateKey name="reset_password" /></a>
            </form>
        );
    }
}
