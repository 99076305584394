/**
 * Created by michielknevels on 13/09/17.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import TranslationUtil from '../../../shared-javascript/util/translation/TranslationUtil';


// translates a translationkey to a string
// Translations are managed in android strings
class TranslateKey extends PureComponent {
    getTranslation() {
        let translated = TranslationUtil.translateKey(this.props.name);
        if (translated) {
            translated = this.changeTranslation(translated);
            return translated;
        }
        return `KNF: ${this.props.name}`;
    }

    changeTranslation(translated2) {
        let translated = translated2;
        if (this.props.fullCapitalize) {
            translated = translated.toUpperCase();
        }
        if (this.props.capitalize) {
            translated = translated[0].toUpperCase() + translated.substring(1);
        }
        if (this.props.suffix) {
            translated += this.props.suffix;
        }
        return translated;
    }

    render() {
        return this.getTranslation();
    }
}

const mapStateToProps = (state) => {
    const { language } = state;
    return { language };
};

export default connect(mapStateToProps, null)(TranslateKey);
